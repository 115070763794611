import i18next from 'i18next'
import React, { createContext, ReactElement, useMemo } from 'react'
import { I18nextProvider, initReactI18next } from 'react-i18next'

export type TranslationLocale = {
  locale: string;
  translations: Record<string, string>;
}

export type LocalizationProviderProps = {
  locale: string;
  translations: Record<string, string>;
  // defaultLocaleTranslations: TranslationLocale;
  children: React.ReactNode;
}

export type LocaleContextType = {
  locale: string;
}

export const LocaleContext = createContext<LocaleContextType>({
  locale: 'en-US',
})

export default function LocalizationProvider (props: LocalizationProviderProps): ReactElement {
  const { locale, translations, children } = props
  const i18n = useMemo(() => {
    const resources = {
      [locale]: {
        translation: translations,
      },
    }
    const i18n = i18next.use(initReactI18next)
    i18n.init({
      lng: locale,
      returnEmptyString: false,
      fallbackLng: {
        default: [locale],
      },
      interpolation: {
        escapeValue: false,
        prefix: '[[',
        suffix: ']]',
      },
      keySeparator: false,
      resources,
    })
    return i18n
  }, [locale, translations])

  const localeValue = useMemo(
    () => ({
      locale,
    }),
    [locale]
  )

  return (
    <I18nextProvider i18n={i18n}>
      <LocaleContext.Provider value={localeValue}>{children}</LocaleContext.Provider>
    </I18nextProvider>
  )
}
