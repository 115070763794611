import { useSwanStyleKeys } from '@vp/ubik-context'
import { CheckoutHeader } from './CheckoutHeader'
import LocalizationProvider from '../contexts/LocalizationProvider'
import { FragmentConfig } from '../types/Fragment'
// import { StoreDetails } from '../types/Store'

export interface Props {
  locale: string;
  environment?: string;
  fragmentConfig: FragmentConfig;
  translations: Record<string, string>;
}

export const Fragment = (props: Props) => {
  const locale = props.locale

  // const identity = useIdentity()

  useSwanStyleKeys(['core'])

  return (
    <LocalizationProvider locale={locale} translations={props.translations}>
      <CheckoutHeader
        storeLogo=''
        locale={locale}
        navigations={props.fragmentConfig.nav}
      />
    </LocalizationProvider>
  )
}
