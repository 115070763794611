import { BoundedContent, Column, Divider, GridContainer, Icon, Link, P, ResponsiveImage, Row, Visible } from '@vp/swan'
import { useTranslation } from 'react-i18next'
import { NavOptions } from '../types/Fragment'

type CheckoutHeaderProps = {
  storeLogo: string;
  locale: string;
  navigations: NavOptions;
}

export const CheckoutHeader = ({ storeLogo, locale, navigations }: CheckoutHeaderProps) => {
  const { t } = useTranslation()

  const navigateToHomePage = async () => {
    window.location.href = `${window.location.origin}/${locale}`
  }

  const navigateToHelpCenterPage = async () => {
    window.location.href = `${window.location.origin}${navigations.customerCareContact}`
  }

  return (
    <>
      <BoundedContent style={{ maxWidth: '1440px' }}>
        <GridContainer>
          <Row paddingY={3} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* Left: Logo */}
            <Column span={6}>
              <Link href='#' onClick={(e: { preventDefault: () => void; }) => { e.preventDefault(); navigateToHomePage() }}>
                <ResponsiveImage src={storeLogo} height={64} loading='eager' />
              </Link>
            </Column>

            {/* Right: Help Center */}
            <Column span={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Link
                href='#' onClick={(e: { preventDefault: () => void; }) => { e.preventDefault(); navigateToHelpCenterPage() }}
                skin='unstyled' style={{ display: 'flex', alignItems: 'center' }}
              >
                <Icon iconType='helpQuery' size='24p' />
                <Visible lg xl>
                  <P fontSize='small' ml='3'>{t('help-center')}</P>
                </Visible>
              </Link>
            </Column>
          </Row>
          <Divider marginX={4} />
          <Row paddingBottom={4} />
        </GridContainer>
      </BoundedContent>
    </>
  )
}
