import { type IdentityProviderProps } from '@vp/ubik-context'

type VPAuthConfig = IdentityProviderProps['auth']

export const createAuthConfig = (): VPAuthConfig => ({
  culture: '',
  options: {
    clientID: '',
    site: '',
    storeId: '',
    vcsStoreUrl: '',
    vcsStoreLogoUrl: '',
    requireSession: true,
  },
})
