import { clientMount } from '@vp/ubik-fragment-react'
import type { MountWithRootOptions } from '@vp/ubik-fragment-types'
import { Fragment, Props, } from '../components/fragment'
import { IdentityProvider } from '@vp/ubik-context'
import { createAuthConfig } from '../utils/auth'

export const mount: MountWithRootOptions<Props> = async (
  rootElement,
  renderProps,
  rootOptions
) => {
  const component = (
    <IdentityProvider auth={createAuthConfig()}>
      <Fragment {...renderProps} />
    </IdentityProvider>
  )

  return clientMount(component, rootElement, rootOptions)
}
